<template>
    <div class="item">
        <el-form ref="mainForm" :model="mainForm" :rules="rules" label-width="80px">
            <el-form-item label="焦点图" prop="images">
                <imageUploader :imageList="imageList" v-on:success="uploadImage" v-on:remove="removeImage" :isMultiple="true"></imageUploader>
            </el-form-item>
            <el-form-item label="标题" prop="title">
                <el-input v-model="mainForm.title" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="简介" prop="introduce">
                <el-input type="textarea" v-model="mainForm.introduce" :autosize="true" style="width:600px"></el-input>
            </el-form-item>
            <el-form-item label="分类" prop="artistId">
                <el-select v-model="mainForm.artistId" placeholder="请选择">
                    <el-option v-for="item in artistList" :key="item.id" :label="item.fullName" :value="item.id"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="价格" prop="price">
                <el-input v-model="mainForm.price" onkeyup="value=value.replace(/[^\d]/g,'')" style="width:200px"></el-input>
            </el-form-item>
            <el-form-item label="数量" prop="amount">
                <el-input v-model="mainForm.amount" onkeyup="value=value.replace(/[^\d]/g,'')" style="width:200px"></el-input>
            </el-form-item>
            <el-form-item label="更多" style="display:none">
                <el-row class="pb-2">
                    <el-col :span="7">
                        <el-input v-model="mainForm.extCaizhi" placeholder="材质"></el-input>
                    </el-col>
                    <el-col class="text-center" :span="1">-</el-col>
                    <el-col :span="7">
                        <el-input v-model="mainForm.extXingzhi" placeholder="形制"></el-input>
                    </el-col>
                    <el-col class="text-center" :span="1">-</el-col>
                    <el-col :span="7">
                        <el-input v-model="mainForm.extNiandai" placeholder="年代"></el-input>
                    </el-col>
                </el-row>
                <el-row class="pb-2">
                    <el-col :span="7">
                        <el-input v-model="mainForm.extKuanshi" placeholder="款式"></el-input>
                    </el-col>
                    <el-col class="text-center" :span="1">-</el-col>
                    <el-col :span="7">
                        <el-input v-model="mainForm.extJinyin" placeholder="釿印"></el-input>
                    </el-col>
                    <el-col class="text-center" :span="1">-</el-col>
                    <el-col :span="7">
                        <el-input v-model="mainForm.extJiancangyin" placeholder="鉴藏印"></el-input>
                    </el-col>
                </el-row>
                <el-row class="pb-2">
                    <el-col :span="7">
                        <el-input v-model="mainForm.extTiba" placeholder="题跋"></el-input>
                    </el-col>
                    <el-col class="text-center" :span="1">-</el-col>
                    <el-col :span="7">
                        <el-input v-model="mainForm.extQiantiao" placeholder="签条"></el-input>
                    </el-col>
                    <el-col class="text-center" :span="1">-</el-col>
                    <el-col :span="7">
                        <el-input v-model="mainForm.extChicun" placeholder="尺寸"></el-input>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="23">
                        <el-input v-model="mainForm.extShuoming" placeholder="说明"></el-input>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="详情">
                <el-row>
                    <el-col v-if="mainForm.details.length > 0" :span="10" class="pr-1">
                        <div class="padding" style="border:#d1d5dd 1px solid;border-radius:2px">
                            <template v-for="(item, index) in mainForm.details">
                                <img :src="CDN + item" @click="removeDetail(item)" title="移除图片" :key="index" style="width:100%;display:block;cursor:pointer;" />
                            </template>
                        </div>
                    </el-col>
                    <el-col :span="14">
                        <imageUploader v-on:success="uploadDetail" :isMultiple="false" :showPreview="false"></imageUploader>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="上架售卖">
                <el-switch v-model="mainForm.state" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
            </el-form-item>
            <el-form-item>
                <el-button @click="goBack">取消</el-button>
                <el-button type="primary" @click="submit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import imageUploader from "@/components/imageUploader";
export default {
    components: { imageUploader },
    data() {
        return {
            mainForm: {
                title: "",
                introduce: "",
                images: [],
                artistId: "0",
                price: "",
                amount:"",
                details: [], //详细
                extCaizhi: "", //材质
                extXingzhi: "", //形制
                extNiandai: "", //年代
                extKuanshi: "", //款式
                extJinyin: "", //釿印
                extJiancangyin: "", //鉴藏印
                extTiba: "", //题跋
                extQiantiao: "", //签条
                extChicun: "", //尺寸
                extShuoming: "", //说明
                state: 1, //状态 0不可售 1可售
            },
            rules: {
                title: [{ required: true, message: "请输入作品名称", trigger: "blur" }],
                introduce: [{ required: true, message: "请输入作品简介", trigger: "blur" }],
                artist: [{ required: true, message: "请选择作者", trigger: "blur" }],
                images: [{ required: true, message: "请上传图片", trigger: "change" }],
                price: [{ required: true, message: "请输入价格（单位：元）", trigger: "blur" }],
                amount: [{ required: true, message: "请输入库存数量", trigger: "blur" }],
            },
            CDN: this.$cdn(),
            artistList: [],
            itemId: null,
            imageList: [],
        };
    },
    mounted() {
        // edit
        let itemId = this.$route.query.itemId;
        if (itemId) {
            this.itemId = itemId;
            this.$http({
                url: "/sys/art/getItem",
                data: {
                    id: itemId,
                },
            }).then((res) => {
                this.mainForm.title = res.data.title;
                this.mainForm.introduce = res.data.introduce;
                this.mainForm.images = JSON.parse(res.data.images);
                this.mainForm.artistId = res.data.artistId;
                this.mainForm.price = res.data.price;
                this.mainForm.amount = res.data.amount;
                if (res.data.details && res.data.details != "") {
                    this.mainForm.details = JSON.parse(res.data.details);
                }
                this.mainForm.extCaizhi = res.data.extCaizhi;
                this.mainForm.extXingzhi = res.data.extXingzhi;
                this.mainForm.extNiandai = res.data.extNiandai;
                this.mainForm.extKuanshi = res.data.extKuanshi;
                this.mainForm.extJinyin = res.data.extJinyin;
                this.mainForm.extJiancangyin = res.data.extJiancangyin;
                this.mainForm.extTiba = res.data.extTiba;
                this.mainForm.extQiantiao = res.data.extQiantiao;
                this.mainForm.extChicun = res.data.extChicun;
                this.mainForm.extShuoming = res.data.extShuoming;
                this.mainForm.state = res.data.state;
                for (let i = 0; i < this.mainForm.images.length; i++) {
                    this.imageList.push({
                        uid: this.getGuid(),
                        url: this.$cdn() + this.mainForm.images[i],
                        imageUrl: this.mainForm.images[i],
                    });
                }
            });
        }
        this.loadArtist();
    },
    methods: {
        // 上传焦点图
        uploadImage: function(val) {
            this.mainForm.images.push(val);
        },
        // 移除焦点图
        removeImage: function(val) {
            for (var i = 0; i < this.mainForm.images.length; i++) {
                if (this.mainForm.images[i] == val.imageUrl) {
                    this.mainForm.images.splice(i, 1);
                    break;
                }
            }
        },
        // 上传详情图
        uploadDetail: function(val) {
            this.mainForm.details.push(val);
        },
        // 移除详情图
        removeDetail: function(val) {
            this.$confirm("确定要移除此内容吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    for (var i = 0; i < this.mainForm.details.length; i++) {
                        if (this.mainForm.details[i] == val) {
                            this.mainForm.details.splice(i, 1);
                            break;
                        }
                    }
                })
                .catch(() => {});
        },
        loadArtist: function() {
            this.$http({
                url: "/sys/art/artistList",
                data: {
                    pageNo: 1,
                    pageSize: 100,
                },
            }).then((res) => {
                this.artistList = res.data.records;
                if (this.itemId == null) {
                    this.mainForm.artistId = this.artistList[0].id;
                }
            });
        },
        submit: function() {
            this.$refs.mainForm.validate((valid) => {
                if (valid) {
                    if (this.itemId == null) {
                        // create
                        this.$http({
                            url: "/sys/art/createItem",
                            data: {
                                title: this.mainForm.title,
                                introduce: this.mainForm.introduce,
                                images: JSON.stringify(this.mainForm.images),
                                artistId: this.mainForm.artistId,
                                price: this.mainForm.price,
                                amount : this.mainForm.amount,
                                details: JSON.stringify(this.mainForm.details),
                                extCaizhi: this.mainForm.extCaizhi,
                                extXingzhi: this.mainForm.extXingzhi,
                                extNiandai: this.mainForm.extNiandai,
                                extKuanshi: this.mainForm.extKuanshi,
                                extJinyin: this.mainForm.extJinyin,
                                extJiancangyin: this.mainForm.extJiancangyin,
                                extTiba: this.mainForm.extTiba,
                                extQiantiao: this.mainForm.extQiantiao,
                                extChicun: this.mainForm.extChicun,
                                extShuoming: this.mainForm.extShuoming,
                                state: this.mainForm.state,
                            },
                        }).then((res) => {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                            });
                            this.$router.push("/item/list");
                        });
                    } else {
                        // update
                        this.$http({
                            url: "/sys/art/updateItem",
                            data: {
                                id: this.itemId,
                                title: this.mainForm.title,
                                introduce: this.mainForm.introduce,
                                images: JSON.stringify(this.mainForm.images),
                                artistId: this.mainForm.artistId,
                                price: this.mainForm.price,
                                amount : this.mainForm.amount,
                                details: JSON.stringify(this.mainForm.details),
                                extCaizhi: this.mainForm.extCaizhi,
                                extXingzhi: this.mainForm.extXingzhi,
                                extNiandai: this.mainForm.extNiandai,
                                extKuanshi: this.mainForm.extKuanshi,
                                extJinyin: this.mainForm.extJinyin,
                                extJiancangyin: this.mainForm.extJiancangyin,
                                extTiba: this.mainForm.extTiba,
                                extQiantiao: this.mainForm.extQiantiao,
                                extChicun: this.mainForm.extChicun,
                                extShuoming: this.mainForm.extShuoming,
                                state: this.mainForm.state,
                            },
                        }).then((res) => {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                            });
                            this.$router.push("/item/list");
                        });
                    }
                }
            });
        },
        // 后退
        goBack: function() {
            this.$router.go(-1);
        },
        getGuid: function() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
    },
};
</script>
